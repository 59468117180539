import { ActionMenu, ActionMenuItem, FormInput, FormSelect, Popover } from '@systemeio/ui-shared'
import { getFunnelSteps } from 'modules/courses/courses/api/sales-funnels-api'
import { updateModule } from 'modules/courses/courses/curriculum/api/module-api'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import {
  CreateModuleErrorsInterface,
  CreateModuleInterface,
  ModuleInterface,
} from 'modules/courses/courses/curriculum/types/module-interface'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import { useSalesFunnels } from 'modules/courses/courses/hooks/use-sales-funnels'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import { SalesFunnelInterface } from 'modules/courses/courses/types/sales-funnel-interface'
import { useRouter } from 'next/router'
import React, { memo, useEffect, useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import { ActionMenuLinkItem } from 'shared/components/table/components/action-menu-link-item'
import { TLocoType, useLocoTranslation } from 'shared/hooks/use-loco-translation'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { FunnelStepInterface } from 'shared/types/payment-interface'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { KeyedMutator } from 'swr'
import { getCourseQuizUrl } from '../../../module/utils/get-course-quiz-url'
import { useModuleApi } from '../hooks/use-module-api'

interface ModuleActionsProps {
  module: ModuleInterface
  mutate: KeyedMutator<CourseCurriculumInterface>
}

const defaultErrors: CreateModuleErrorsInterface = {
  fields: {
    name: '',
  },
}

function ModuleActions({ module, mutate }: ModuleActionsProps) {
  const { t } = useLocoTranslation()
  const router = useRouter()

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)
  const [isQuizOpen, setIsQuizOpen] = useState(false)
  const [editErrors, setEditErrors] = useState(defaultErrors)
  const caption = t('dashboard.course.module_title')
  const captionQuiz = t('dashboard.course.module.quiz_title')
  const { creatingQuizForModule, removeModuleWithLectures } = useModuleApi()

  const hasLectures = module.lectures?.length > 0

  const onRemoveModule = async (moduleId: CourseId) => {
    try {
      await removeModuleWithLectures(moduleId)

      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.filter(module => module.id !== moduleId),
          }
        }
      }, false)
    } catch (e) {
      throw e
    }
  }

  const onCreateQuiz = async (moduleId: CourseId) => {
    try {
      const response = await creatingQuizForModule(moduleId)
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (module.id === moduleId) {
                return {
                  ...module,
                  quiz: response,
                }
              }
              return module
            }),
          }
        }
      }, false)
      router.push(getCourseQuizUrl(response.id))
    } catch (e) {
      throw e
    }
  }

  const onEditModule = async (moduleId: CourseId, data: CreateModuleInterface) => {
    try {
      await fetchWithFieldErrors(async () => {
        const response = await updateModule(moduleId, data)
        await mutate(data => {
          if (data) {
            return {
              ...data,
              modules: data.modules.map(module => {
                if (module.id === moduleId) {
                  return response.data
                }
                return module
              }),
            }
          }
        }, false)
      }, setEditErrors)
    } catch (e) {
      throw e
    }
  }

  return (
    <div className={`flex items-center justify-end`}>
      <div {...nonDraggableProps}>
        <ActionMenu
          menuItems={[
            module.quiz ? (
              <ActionMenuLinkItem
                key="module-quiz"
                isNext={true}
                label={t('dashboard.course.module_quiz_title')}
                href={getCourseQuizUrl(module.quiz.id)}
              />
            ) : (
              <ActionMenuItem
                key="module-create-quiz"
                label={t('dashboard.course.module.create_quiz')}
                onClick={() => {
                  setIsQuizOpen(true)
                }}
              />
            ),
            <ActionMenuItem
              key="module-settings"
              label={t('dashboard.actions.title.settings')}
              onClick={() => {
                setIsSettingsOpen(true)
              }}
            />,
            <ActionMenuItem
              key="module-remove"
              label={t('dashboard.actions.title.remove')}
              onClick={() => {
                setIsRemoveOpen(true)
              }}
            />,
          ]}
        />

        <EditModal
          fullCaption={t('dashboard.course.module_edit_settings_title')}
          opened={isSettingsOpen}
          onClose={() => {
            setIsSettingsOpen(false)
          }}
          instance={module}
          editable={[
            {
              field: 'name',
              onRender: (value, onChange) => (
                <FormInput
                  key="module-edit-name"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  label={t('global.name')}
                  error={editErrors.fields.name}
                />
              ),
            },
            {
              field: 'salesFunnelStep',
              onRender: (value, onChange) => (
                <SalesFunnelStepSelector
                  key="module-sales-funnel-step"
                  value={value}
                  salesFunnel={module.salesFunnel}
                  onChange={onChange}
                />
              ),
            },
          ]}
          onEdit={data => onEditModule(module.id, data)}
        />
        <Confirmation
          opened={isQuizOpen}
          onClose={() => setIsQuizOpen(false)}
          onConfirm={() => onCreateQuiz(module.id)}
          confirmationText={t('dashboard.course.module.create_modal_quiz')}
          toastCaption={t('dashboard.actions.created', { module: captionQuiz?.toLowerCase() })}
        />
        <Confirmation
          opened={isRemoveOpen}
          onClose={() => setIsRemoveOpen(false)}
          onConfirm={() => onRemoveModule(module.id)}
          confirmationText={t(
            hasLectures
              ? 'dashboard.actions.remove_with_lectures_confirmation'
              : 'dashboard.actions.remove_confirmation',
            {
              module: caption?.toLowerCase(),
            },
          )}
          toastCaption={t('dashboard.actions.removed', { module: caption?.toLowerCase() })}
        />
      </div>
    </div>
  )
}

const getDefaultSalesFunnelSelect = (t: TLocoType) => {
  return {
    id: 'default',
    caption: t('dashboard.course.funnel_default_title'),
  }
}

const getSelectDataCaptionFromName = (data: SalesFunnelInterface[]) => {
  return data.map(instance => ({
    id: instance.id,
    caption: instance.name,
  }))
}

interface SalesFunnelStepSelectorProps {
  value: number | null
  onChange: (value: number | null) => void
  salesFunnel: number | null
}
const SalesFunnelStepSelector = ({
  value,
  onChange,
  salesFunnel,
}: SalesFunnelStepSelectorProps) => {
  const { t } = useLocoTranslation()
  const { data: salesFunnelsData } = useSalesFunnels()
  const [funnelStepsCache, setFunnelStepsCache] = useState<Record<number, FunnelStepInterface[]>>(
    {},
  )
  const [activeFunnel, setActiveFunnel] = useState<number | null | string>(salesFunnel)

  useEffect(() => {
    if (typeof activeFunnel == 'number') {
      if (activeFunnel !== salesFunnel) {
        onChange(null)
      }
      const getFunnelStepsByFunnelId = async () => {
        if (!funnelStepsCache[activeFunnel]) {
          const { data } = await getFunnelSteps(activeFunnel)
          setFunnelStepsCache(prev => {
            return {
              ...prev,
              [activeFunnel]: data,
            }
          })
          return data
        }
      }
      getFunnelStepsByFunnelId()
    }
    if (activeFunnel === 'default') {
      onChange(null)
    }
  }, [activeFunnel])

  return (
    <>
      <div className="flex flex-col justify-between [&>*]:flex-1 gap-5">
        <FormSelect
          isPreFetching={!salesFunnelsData}
          data={[
            getDefaultSalesFunnelSelect(t),
            ...getSelectDataCaptionFromName(salesFunnelsData || []),
          ]}
          label={
            <>
              <div className={`flex gap-3 items-center`}>
                {t('dashboard.course.sales_page_title')}
                <Popover
                  label={t('dashboard.course.sales_page_popover_title')}
                  popoverClassName={'w-[200px] sm:w-[350px]'}
                >
                  {show => (
                    <QuestionMarkIcon
                      className={`${
                        show ? 'fill-blue' : 'fill-gray-100'
                      } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                    />
                  )}
                </Popover>
              </div>
            </>
          }
          className={'sm:min-w-0'}
          value={activeFunnel || getDefaultSalesFunnelSelect(t).id}
          onChange={value => value && setActiveFunnel(value)}
          withoutCloseIcon
        />
        {activeFunnel && typeof activeFunnel === 'number' && (
          <FormSelect
            className={'sm:min-w-0 align-bottom'}
            data={
              funnelStepsCache[activeFunnel]
                ? getSelectDataCaptionFromName(funnelStepsCache[activeFunnel])
                : undefined
            }
            label={t('dashboard.course.funnel_step_title')}
            value={value || undefined}
            onChange={value => onChange(value ?? null)}
            withoutCloseIcon
          />
        )}
      </div>
    </>
  )
}

export default memo(ModuleActions)
