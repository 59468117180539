import { FormInput, FormSelect, Checkbox, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { addStudent } from 'modules/courses/courses/curriculum/api/students-api'
import {
  AccessTypeEnum,
  getCourseAccessTypeDataOptions,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import {
  CreateStudentApiInterface,
  CreateStudentErrorsInterface,
  CreateStudentInterface,
} from 'modules/courses/courses/curriculum/types/student-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import React, { useState } from 'react'
import CreateModal from 'shared/components/create-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'

const defaultValues: CreateStudentInterface = {
  firstName: '',
  lastName: '',
  email: '',
  accessType: AccessTypeEnum.fullAccess,
  modules: [],
}

const defaultErrors: CreateStudentErrorsInterface = {
  fields: {
    firstName: '',
    lastName: '',
    email: '',
    accessType: '',
  },
}

function CreateStudentModal({
  courseCurriculumData,
  courseId,
  disabledButton,
}: {
  courseCurriculumData: CourseCurriculumInterface | undefined
  courseId: CourseId
  disabledButton?: boolean
}) {
  const { t } = useLocoTranslation()
  const [errors, setErrors] = useState(defaultErrors)
  const [opened, setOpened] = useState(false)

  const handleCreate = async (data: CreateStudentApiInterface) => {
    const studentData: CreateStudentApiInterface = {
      accessType: data.accessType || defaultValues.accessType,
      email: data.email,
      lastName: data.lastName || defaultValues.lastName,
      firstName: data.firstName || defaultValues.firstName,
      modules: data.accessType === AccessTypeEnum.partialAccess ? data.modules : undefined,
    }
    await fetchWithFieldErrors(async () => {
      await addStudent(courseId, studentData)
      onClose()
    }, setErrors)
  }

  const afterLeave = () => {
    setErrors(defaultErrors)
  }

  const onClose = () => setOpened(false)

  return (
    <>
      <Tooltip
        disabled={!disabledButton}
        mode={'hover'}
        label={t('dashboard.course.add_student_modal.tooltip_text')}
        stayOnHover
      >
        <PrimaryButton
          onClick={() => setOpened(true)}
          disabled={disabledButton || !courseCurriculumData}
        >
          <AddIcon />
          <span className="xl:block hidden whitespace-nowrap">
            {t('dashboard.course.add_student_modal.trigger_title')}
          </span>
        </PrimaryButton>
      </Tooltip>
      <CreateModal
        opened={opened}
        onClose={onClose}
        afterLeave={afterLeave}
        defaultValues={defaultValues}
        caption={t('dashboard.students.title')}
        creatable={[
          {
            field: 'firstName',
            onRender: (value, onChange) => (
              <FormInput
                key="student-firstName"
                label={t('dashboard.user.first_name')}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errors.fields.firstName}
              />
            ),
          },
          {
            field: 'lastName',
            onRender: (value, onChange) => (
              <FormInput
                key="student-lastName"
                label={t('dashboard.user.last_name')}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errors.fields.lastName}
              />
            ),
          },
          {
            field: 'email',
            onRender: (value, onChange) => (
              <FormInput
                key="student-email"
                label={t('global.email')}
                required
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errors.fields.email}
              />
            ),
          },
          {
            field: 'accessType',
            onRender: (value, onChange) => (
              <FormSelect
                key="student-accessType"
                className={'sm:min-w-0'}
                data={getCourseAccessTypeDataOptions(t)}
                label={t('dashboard.course.student_access_type_title')}
                required
                value={value}
                onChange={value => value && onChange(value)}
                error={errors.fields.accessType}
                withoutCloseIcon
              />
            ),
          },
          {
            field: 'modules',
            shouldRender: state =>
              !!(state.accessType === AccessTypeEnum.partialAccess && courseCurriculumData),
            onRender: (value, onChange, state) => (
              <div key="student-accessType-modules">
                {state.accessType === AccessTypeEnum.partialAccess && courseCurriculumData && (
                  <div
                    className={`text-sm font-medium flex-col gap-1 ${
                      courseCurriculumData.modules && courseCurriculumData.modules.length === 0
                        ? 'hidden'
                        : 'flex'
                    }`}
                  >
                    <ul className={'flex flex-wrap flex-row gap-5'}>
                      {courseCurriculumData.modules.map(el => {
                        const isSelected = value.includes(el.id)
                        const label = el.name
                        return (
                          <li key={el.id}>
                            <Checkbox
                              value={isSelected}
                              label={
                                <div
                                  className={`flex gap-1.5 items-center ${
                                    isSelected && '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                                  }`}
                                >
                                  {label}
                                </div>
                              }
                              onChange={() =>
                                isSelected
                                  ? onChange(value.filter(id => id !== el.id))
                                  : onChange([...value, el.id])
                              }
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
              </div>
            ),
          },
        ]}
        onCreate={handleCreate}
        requiredFields={['email']}
      />
    </>
  )
}

export default CreateStudentModal
