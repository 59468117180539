import {
  ActionMenu,
  ActionMenuItem,
  Checkbox,
  FormInput,
  Hint,
  Popover,
} from '@systemeio/ui-shared'
import {
  activateLecture,
  duplicateLecture,
  removeLecture,
  updateLecture,
} from 'modules/courses/courses/curriculum/api/lecture-api'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import {
  EditLectureErrorsInterface,
  EditLectureInterface,
  LectureInterface,
} from 'modules/courses/courses/curriculum/types/lecture-interface'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import { useRouter } from 'next/router'
import React, { memo, useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import { ActionMenuLinkItem } from 'shared/components/table/components/action-menu-link-item'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { KeyedMutator } from 'swr'
import { getCourseLectureEmailUrl } from '../../utils/get-course-lecture-email-url'
import { LectureEmailAction } from './lecture-email-action'

interface LectureActionsProps {
  lecture: LectureInterface
  mutate: KeyedMutator<CourseCurriculumInterface>
  moduleId: number
}

const defaultErrors: EditLectureErrorsInterface = {
  fields: {
    name: '',
    delayBeforePreviousLecture: '',
  },
}

function LectureActions({ lecture, mutate, moduleId }: LectureActionsProps) {
  const { t } = useLocoTranslation()
  const router = useRouter()
  const { courseId } = router.query
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    LectureInterface
  >({
    method: RequestMethodsEnum.post,
  })
  const { fetcher: deleteFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.delete>({
    method: RequestMethodsEnum.delete,
  })
  const { fetcher: putFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    LectureInterface
  >({
    method: RequestMethodsEnum.put,
  })
  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false)
  const [editErrors, setEditErrors] = useState(defaultErrors)

  const [isRemoveOpen, setIsRemoveOpen] = useState(false)
  const caption = t('dashboard.course.lecture_title').toLowerCase()
  const lectureId = lecture.id

  const handleActivateLecture = async () => {
    const update = lecture.active ? 'deactivate' : 'activate'
    await activateLecture(update, lecture.id, postFetcher)
    await mutate(data => {
      if (data) {
        return {
          ...data,
          modules: data.modules.map(module => {
            if (module.id === moduleId) {
              return {
                ...module,
                lectures: module.lectures.map(lecture => {
                  if (lecture.id === lectureId) {
                    return { ...lecture, active: !lecture.active }
                  }
                  return lecture
                }),
              }
            }
            return module
          }),
        }
      }
    }, false)
  }

  const handleDuplicateLecture = async () => {
    const response = await duplicateLecture(lecture.id, postFetcher)
    await mutate(data => {
      if (data) {
        return {
          ...data,
          modules: data.modules.map(module => {
            if (module.id === moduleId) {
              const lectureIndex = module.lectures.findIndex(({ id }) => lecture.id === id)
              const lectures = [...module.lectures]
              lectures.splice(lectureIndex + 1, 0, { ...response })
              return {
                ...module,
                lectures,
              }
            }
            return module
          }),
        }
      }
    }, false)
  }

  const handleRemoveLecture = async () => {
    await removeLecture(lectureId, deleteFetcher)
    await mutate(data => {
      if (data) {
        return {
          ...data,
          modules: data.modules.map(module => {
            if (module.id === moduleId) {
              return {
                ...module,
                lectures: module.lectures.filter(lecture => lecture.id !== lectureId),
              }
            }
            return module
          }),
        }
      }
    }, false)
  }

  const handleEditLecture = async (data: EditLectureInterface) => {
    await fetchWithFieldErrors(async () => {
      const response = await updateLecture(lectureId, data, putFetcher)
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (module.id === moduleId) {
                return {
                  ...module,
                  lectures: module.lectures.map(lecture => {
                    if (lecture.id === lectureId) {
                      return response
                    }
                    return lecture
                  }),
                }
              }
              return module
            }),
          }
        }
      }, false)
    }, setEditErrors)
  }

  return (
    <div className="flex items-center justify-end">
      <div {...nonDraggableProps}>
        <ActionMenu
          dataTestAttributes={{ 'data-test': 'module-lecture-actions' }}
          menuItems={[
            <ActionMenuItem
              key={'action-activate'}
              label={
                lecture.active
                  ? t('dashboard.actions.title.deactivate')
                  : t('dashboard.actions.title.activate')
              }
              onClick={() => {
                setIsActivateOpen(true)
              }}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-status' }}
            />,
            <ActionMenuLinkItem
              key={'action-edit'}
              label={t('dashboard.actions.title.edit')}
              href={lecture.pageEditUrl}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-edit' }}
            />,
            <ActionMenuLinkItem
              key={'action-open'}
              label={t('dashboard.actions.title.open')}
              href={lecture.loginLinkUrl}
              openInNew={true}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-view' }}
            />,
            <ActionMenuItem
              key={'action-settings'}
              label={t('dashboard.actions.title.settings')}
              onClick={() => {
                setIsSettingsOpen(true)
              }}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-settings' }}
            />,
            lecture.currentEmail?.id ? (
              <ActionMenuLinkItem
                key={'action-edit-drip'}
                label={t('dashboard.course.lecture.actions.edit_drip')}
                isNext={true}
                href={getCourseLectureEmailUrl({
                  courseId: Number(courseId as string),
                  lectureId: lecture.id,
                })}
                dataTestAttributes={{ 'data-test': 'module-lecture-action-edit-drip' }}
              />
            ) : (
              <ActionMenuItem
                key={'action-create-drip'}
                label={
                  <div className="flex gap-3 items-center [&>svg]:fill-blue [&>svg]:main-transition-colors">
                    {t('dashboard.course.lecture.actions.create_drip')}
                    <Popover
                      label={t('dashboard.course.lecture.actions.create_drip_popover')}
                      popoverClassName="w-[200px] sm:w-[300px] z-[99999]"
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                }
                onClick={() => {
                  setIsCreateEmailOpen(true)
                }}
                dataTestAttributes={{ 'data-test': 'module-lecture-action-create-drip' }}
              />
            ),
            <ActionMenuItem
              key={'action-duplicate'}
              label={t('dashboard.actions.title.duplicate')}
              onClick={() => {
                setIsDuplicateOpen(true)
              }}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-duplicate' }}
            />,
            <ActionMenuItem
              key={'action-remove'}
              label={t('dashboard.actions.title.remove')}
              onClick={() => {
                setIsRemoveOpen(true)
              }}
              dataTestAttributes={{ 'data-test': 'module-lecture-action-remove' }}
            />,
          ]}
        />
        <LectureEmailAction
          lecture={lecture}
          isOpened={isCreateEmailOpen}
          onClose={() => setIsCreateEmailOpen(false)}
        />
        <EditModal
          fullCaption={t('dashboard.course.lecture_edit_settings_title')}
          opened={isSettingsOpen}
          onClose={() => {
            setIsSettingsOpen(false)
          }}
          instance={lecture}
          editable={[
            {
              field: 'name',
              onRender: (value, onChange) => (
                <FormInput
                  value={value}
                  key={`${lecture.id}-name`}
                  onChange={e => onChange(e.target.value)}
                  label={t('global.name')}
                  error={editErrors.fields.name}
                />
              ),
            },
            {
              field: 'delayBeforePreviousLecture',
              onRender: (value, onChange) => (
                <FormInput
                  value={value}
                  key={`${lecture.id}-delayBeforePreviousLecture`}
                  type="number"
                  onChange={e => onChange(parseInt(e.target.value))}
                  label={
                    <Hint label={t('dashboard.course.lecture_delay_title_tooltip')}>
                      {t('dashboard.course.lecture_delay_title')}
                    </Hint>
                  }
                  error={editErrors.fields.delayBeforePreviousLecture}
                />
              ),
            },
            {
              field: 'showComments',
              onRender: (value, onChange) => (
                <Checkbox
                  key={`${moduleId}-showComments`}
                  label={t('dashboard.course.show_comments_title')}
                  value={value}
                  onChange={onChange}
                />
              ),
            },
          ]}
          onEdit={handleEditLecture}
        />
        <Confirmation
          opened={isRemoveOpen}
          onClose={() => setIsRemoveOpen(false)}
          onConfirm={handleRemoveLecture}
          confirmationText={t('dashboard.actions.remove_confirmation', {
            module: caption?.toLowerCase(),
          })}
          toastCaption={t('dashboard.actions.removed', { module: caption })}
        />
        <Confirmation
          opened={isActivateOpen}
          onClose={() => setIsActivateOpen(false)}
          onConfirm={handleActivateLecture}
          confirmationText={t('dashboard.actions.activate_confirmation', {
            activate: lecture.active
              ? t('global.deactivate').toLowerCase()
              : t('global.activate').toLowerCase(),
            module: caption?.toLowerCase(),
          })}
          toastCaption={t('dashboard.actions.activated', {
            module: caption,
            activated: lecture.active
              ? t('dashboard.actions.deactivated_caption').toLowerCase()
              : t('dashboard.actions.activated_caption').toLowerCase(),
          })}
        />
        <Confirmation
          confirmationText={t('dashboard.course.lecture_duplicate_title')}
          opened={isDuplicateOpen}
          onClose={() => setIsDuplicateOpen(false)}
          onConfirm={handleDuplicateLecture}
          toastCaption={t('dashboard.course.lecture_duplicated_title')}
        />
      </div>
    </div>
  )
}

export default memo(LectureActions)
