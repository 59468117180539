import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Disclosure, Transition } from '@headlessui/react'
import ModuleActions from 'modules/courses/courses/curriculum/components/module-actions'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { ModuleInterface } from 'modules/courses/courses/curriculum/types/module-interface'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import React, { useEffect, useState } from 'react'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'
import { KeyedMutator } from 'swr'

interface CourseModuleProps {
  module: ModuleInterface
  children: JSX.Element | JSX.Element[]
  mutate: KeyedMutator<CourseCurriculumInterface>
  childrenCount: number
  openDefault: boolean
  onSelect: (CurriculumId: number) => void
}

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

function CourseModule({
  module,
  children,
  mutate,
  childrenCount,
  openDefault,
  onSelect,
}: CourseModuleProps) {
  // we should keep empty modules opened because it's better for UX and also closed empty modules caused this error https://app.rollbar.com/a/systemeio/fix/item/dashboard-frontend/8260
  const shouldBeAlwaysOpened = module.lectures.length === 0

  const [open, setOpen] = useState(shouldBeAlwaysOpened)

  useEffect(() => {
    if (shouldBeAlwaysOpened) setOpen(shouldBeAlwaysOpened)
  }, [shouldBeAlwaysOpened])

  const { attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
    id: module.id,
    data: {
      type: 'container',
      children: module.lectures,
    },
    animateLayoutChanges,
  })
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  }

  return (
    <div
      className="flex flex-col gap-3 focus-visible:outline-none cursor-move"
      ref={setNodeRef}
      style={{ ...style }}
      {...attributes}
      {...listeners}
      data-test={`course-module-${module.id}-div`}
    >
      <div className="overflow-hidden rounded-lg z-0">
        <Disclosure defaultOpen={openDefault || shouldBeAlwaysOpened}>
          <>
            <div
              className={`flex items-center bg-blue-100 ${
                childrenCount > 0 ? '' : 'overflow-hidden rounded-b-lg'
              }`}
            >
              <div
                onClick={() => onSelect(module.id)}
                className="lg:flex-[55_1_0%] flex-[25_1_0%] order-1 truncate"
              >
                <Disclosure.Button
                  onClick={() => setOpen(shouldBeAlwaysOpened || !open)}
                  className={
                    'max-w-fit relative flex items-center w-full h-full gap-2 cursor-pointer text-start text-darkblue lg:px-6 px-4 py-4 font-bold font-inter lg:text-[17px] text-base'
                  }
                  {...nonDraggableProps}
                  data-test={'course-module-button-show-lecture'}
                >
                  <ChevronDownIcon
                    className={`h-3 w-3 ${open ? 'rotate-180 transform' : ''} flex-shrink-0`}
                    aria-hidden="true"
                  />
                  <span className="truncate" data-test={'course-module-name'}>
                    {module.name}
                  </span>
                </Disclosure.Button>
              </div>
              <div className="px-6 py-4 flex-[15_1_0%] order-2 lg:block hidden">
                <div />
              </div>
              <div className="text-darkblue lg:px-6 px-4 py-4 font-bold font-inter text-[17px] truncate lg:order-3 order-2 flex-[5_1_0%]">
                <ModuleActions module={module} mutate={mutate} />
              </div>
            </div>
            <Transition
              show={open}
              enter="transition ease duration-500 transform"
              enterFrom="opacity-0 translate-y-0"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-0"
              data-test={'course-module-expanded-attribute'}
            >
              <Disclosure.Panel static className="bg-white">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        </Disclosure>
      </div>
    </div>
  )
}

export default CourseModule
