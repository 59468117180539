import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Tooltip } from '@systemeio/ui-shared'
import LectureActions from 'modules/courses/courses/curriculum/components/lecture-actions'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { LectureInterface } from 'modules/courses/courses/curriculum/types/lecture-interface'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import { KeyedMutator } from 'swr'
import { twMerge } from 'tailwind-merge'

interface ModuleLectureProps {
  lecture: LectureInterface
  disabled: boolean
  mutate: KeyedMutator<CourseCurriculumInterface>
  moduleId: number
  isLastChild: boolean
  isFetching?: boolean
}

function ModuleLecture({
  lecture,
  disabled,
  mutate,
  moduleId,
  isLastChild,
  isFetching,
}: ModuleLectureProps) {
  const { t } = useLocoTranslation()

  const { setNodeRef, listeners, transform, transition } = useSortable({
    id: lecture.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      className={twMerge(
        'flex flex-col ',
        isLastChild && 'rounded-b-lg',
        'gap-4 lg:gap-0 lg:flex-row items-start lg:items-center even:bg-blue-100 odd:bg-white',
        'border border-transparent hover:border-blue main-transition-colors cursor-move',
        disabled && 'cursor-not-allowed',
        isFetching && 'animate-pulse',
      )}
      style={style}
      ref={disabled ? undefined : setNodeRef}
      {...listeners}
    >
      <div className="lg:px-6 px-4 py-4 w-full truncate flex-[55_1_0%] lg:block hidden">
        <div className="flex items-center gap-3">
          <a
            className="primary-link font-normal text-sm truncate cursor-pointer"
            {...nonDraggableProps}
            href={lecture.pageEditUrl}
            data-test={'module-lecture-link'}
          >
            {lecture.name}
          </a>
        </div>
      </div>
      <div className="lg:px-6 px-4 py-2 w-full truncate  lg:hidden block">
        <div className="flex items-center gap-3 ">
          <div
            className="text-blue font-normal text-sm truncate cursor-default flex-[87_1_0%]"
            {...nonDraggableProps}
          >
            {lecture.name}
          </div>
          <div className="flex items-center gap-3  flex-[15_1_0%]">
            <span className="text-darkblue font-bold whitespace-nowrap">
              <div className="lg:ml-4 flex items-center cursor-default" {...nonDraggableProps}>
                <Tooltip
                  label={lecture.active ? t('global.active') : t('global.inactive')}
                  mode="hover"
                  key="remove-tooltip"
                >
                  <CheckCircleIcon
                    className={`w-[20px]h-[20px] ${lecture.active && 'fill-green'}`}
                  />
                </Tooltip>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full truncate flex-[15_1_0%] lg:block text-darkblue text-sm font-normal lg:px-6 px-4 py-2">
        <div className="flex flex-col gap-1">
          <span data-test={'module-lecture-delay'}>
            {lecture.delayBeforePreviousLecture === 0
              ? t('dashboard.course.lecture_grant_access_immediately')
              : t('dashboard.course.lecture_days_delay_title', {
                  daysCount: lecture.delayBeforePreviousLecture,
                })}
          </span>
          <span className="opacity-60">({t('dashboard.course.lecture_days_delay_subtitle')})</span>
        </div>
      </div>
      <div className="lg:px-6 px-4 lg:py-4 py-2 w-full truncate flex-[10_1_0%] lg:block hidden">
        <div className="flex items-center gap-3">
          <span className="text-darkblue font-bold whitespace-nowrap">
            <div
              className="lg:ml-4 flex items-center cursor-default"
              {...nonDraggableProps}
              data-test={'module-lecture-status'}
            >
              <Tooltip
                label={lecture.active ? t('global.active') : t('global.inactive')}
                mode="hover"
                key="remove-tooltip"
              >
                <CheckCircleIcon
                  className={`w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] ${
                    lecture.active && 'fill-green'
                  }`}
                />
              </Tooltip>
            </div>
          </span>
        </div>
      </div>
      <div className="text-start text-darkblue lg:px-6 px-4 py-4 font-bold font-inter text-[17px] truncate  flex-[5_1_0%]">
        <LectureActions mutate={mutate} lecture={lecture} moduleId={moduleId} />
      </div>
    </div>
  )
}

export default ModuleLecture
