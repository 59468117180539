import {
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import debounce from 'lodash.debounce'
import { changeLecturePosition } from 'modules/courses/courses/curriculum/api/lecture-api'
import { changeModulePosition } from 'modules/courses/courses/curriculum/api/module-api'
import CourseModule from 'modules/courses/courses/curriculum/components/course-module'
import CreateLectureModal from 'modules/courses/courses/curriculum/components/create-lecture-modal'
import ModuleLecture from 'modules/courses/courses/curriculum/components/module-lecture'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { ModuleInterface } from 'modules/courses/courses/curriculum/types/module-interface'
import {
  getSelectedСoursesCurriculumCookie,
  setSelectedСoursesCurriculumCookie,
} from 'modules/courses/courses/curriculum/utils/cookie-utils'
import React, { useCallback, useRef, useState } from 'react'
import { KeyedMutator } from 'swr'

function CourseCurriculumTable({
  modules,
  mutate,
  courseId,
}: {
  modules: ModuleInterface[]
  mutate: KeyedMutator<CourseCurriculumInterface>
  courseId: number
}) {
  const activeIdRef = useRef<UniqueIdentifier | null>(null)
  const clonedItemsRef = useRef<ModuleInterface[] | null>(null)
  const movingToNewModuleRef = useRef(false)
  const [lecturesChangingPosition, setLecturesChangingPosition] = useState<number[]>([])

  const containers = modules.map(module => module.id) as UniqueIdentifier[]
  const checkIfContainer = (element: { id: UniqueIdentifier }) => containers.includes(element.id)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const selectedСoursesCurriculumCookie = getSelectedСoursesCurriculumCookie(courseId)

  const collisionDetectionStrategy = useCallback(
    debounce<CollisionDetection>(
      args => {
        if (activeIdRef.current && checkIfContainer({ id: activeIdRef.current })) {
          return closestCenter({
            ...args,
            droppableContainers: args.droppableContainers.filter(checkIfContainer),
          })
        }
        return closestCorners(args)
      },
      50,
      {
        leading: true,
        trailing: false,
        maxWait: 50,
      },
    ),
    [],
  )

  const findContainer = (id: UniqueIdentifier) => {
    if (containers.includes(id)) {
      return id
    }
    return modules.find(module => {
      const searchLecture = module.lectures.find(lecture => lecture.id === id)
      if (searchLecture) {
        return module
      }
    })?.id
  }

  const onDragCancel = async () => {
    const clonedItems = clonedItemsRef.current
    if (clonedItems) {
      // Reset items to their original state in case items have been Dragged across containers
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: clonedItems,
          }
        }
      })
    }
    activeIdRef.current = null
    clonedItemsRef.current = null
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    const overId = over?.id
    if (containers.includes(active.id) && overId) {
      const activeIndex = modules.findIndex(module => module.id === active.id)
      const overIndex = modules.findIndex(module => module.id === over.id)

      if (activeIndex === overIndex) return

      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: arrayMove(data.modules, activeIndex, overIndex),
          }
        }
      }, false)
      await changeModulePosition(Number(active.id), { new_position: overIndex + 1 })
    }

    const activeContainer = findContainer(active.id)
    if (!activeContainer) {
      activeIdRef.current = null
      return
    }

    if (overId == null) {
      activeIdRef.current = null
      return
    }

    const overContainer = findContainer(overId)
    if (!overContainer) {
      activeIdRef.current = null
      return
    }

    const moduleOver = modules.find(module => module.id === overContainer)
    const moduleActive = modules.find(module => module.id === activeContainer)
    if (!moduleOver || !moduleActive || moduleOver.id !== moduleActive.id) {
      activeIdRef.current = null
      return
    }

    const activeIndex = moduleActive.lectures.findIndex(lecture => lecture.id === active.id)
    const overIndex = moduleOver.lectures.findIndex(lecture => lecture.id === overId)
    if (activeIndex === -1 || overIndex === -1) {
      activeIdRef.current = null
      return
    }

    await mutate(data => {
      if (data) {
        return {
          ...data,
          modules: data.modules.map(module => {
            if (
              activeIndex > module.lectures.length - 1 ||
              overIndex > module.lectures.length - 1
            ) {
              return module
            }
            if (module.id === overContainer) {
              return {
                ...module,
                lectures: arrayMove(module.lectures, activeIndex, overIndex),
              }
            }
            return module
          }),
        }
      }
    }, false)

    try {
      setLecturesChangingPosition(prev => [...prev, Number(active.id)])
      if (movingToNewModuleRef.current) {
        await changeLecturePosition(Number(active.id), {
          new_position: overIndex + 1,
          module: moduleOver.id,
        })
      } else {
        await changeLecturePosition(Number(active.id), {
          new_position: overIndex + 1,
        })
      }
    } catch (error) {
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (
                activeIndex > module.lectures.length - 1 ||
                overIndex > module.lectures.length - 1
              ) {
                return module
              }
              if (module.id === overContainer) {
                return {
                  ...module,
                  lectures: arrayMove(module.lectures, overIndex, activeIndex),
                }
              }
              return module
            }),
          }
        }
      }, false)
    } finally {
      setLecturesChangingPosition(prev => prev.filter(el => el !== Number(active.id)))
    }

    activeIdRef.current = null
  }

  const onDragOver = async ({ active, over }: DragOverEvent) => {
    const overId = over?.id
    if (overId === undefined || overId === null || containers.includes(active.id)) {
      return
    }

    const overContainerId = findContainer(overId)
    const activeContainerId = findContainer(active.id)

    if (!overContainerId || !activeContainerId) {
      return
    }

    if (activeContainerId !== overContainerId) {
      movingToNewModuleRef.current = true
      const activeModule = modules.find(module => module.id === activeContainerId)
      const overModule = modules.find(module => module.id === overContainerId)
      const activeItems = activeModule?.lectures
      const overItems = overModule?.lectures
      const overIndex = overItems?.findIndex(el => el.id === overId)
      const activeIndex = activeItems?.findIndex(el => el.id === active.id)
      let newIndex: number

      if (containers.includes(overId) && overItems) {
        newIndex = overItems.length + 1
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height

        const modifier = isBelowOverItem ? 1 : 0
        if (overItems) {
          newIndex = overIndex && overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }
      }

      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (module.id === activeContainerId) {
                return {
                  ...module,
                  lectures: module.lectures.filter(item => item.id !== active.id),
                }
              }
              if (module.id === overContainerId) {
                const activeModule = modules.find(module => module.id === activeContainerId)
                if (activeModule && typeof activeIndex === 'number') {
                  return {
                    ...module,
                    lectures: [
                      ...module.lectures.slice(0, newIndex),
                      activeModule.lectures[activeIndex],
                      ...module.lectures.slice(newIndex, overModule?.lectures.length),
                    ],
                  }
                }
              }
              return module
            }),
          }
        }
      }, false)
    }
  }

  function onSelectCoursesCurriculum(CurriculumId: number) {
    setSelectedСoursesCurriculumCookie(courseId, CurriculumId)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        activeIdRef.current = active.id
        clonedItemsRef.current = modules
        movingToNewModuleRef.current = false
      }}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={modules} strategy={verticalListSortingStrategy}>
        <div className="flex flex-col mt-5 gap-5">
          {modules.map(module => (
            <CourseModule
              key={module.id}
              module={module}
              mutate={mutate}
              childrenCount={module.lectures.length}
              openDefault={module.id === Number(selectedСoursesCurriculumCookie)}
              onSelect={onSelectCoursesCurriculum}
            >
              <SortableContext items={module.lectures} strategy={verticalListSortingStrategy}>
                {module.lectures.map((lecture, index) => {
                  return (
                    <ModuleLecture
                      key={lecture.id}
                      disabled={lecturesChangingPosition.includes(lecture.id)}
                      isFetching={lecturesChangingPosition.includes(lecture.id)}
                      lecture={lecture}
                      moduleId={module.id}
                      mutate={mutate}
                      isLastChild={index === module.lectures.length - 1}
                      data-test={`module-${module.id}-lecture-${lecture.id}`}
                    />
                  )
                })}
                <div className="py-5">
                  <CreateLectureModal moduleId={module.id} mutate={mutate} />
                </div>
              </SortableContext>
            </CourseModule>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  )
}

export default CourseCurriculumTable
